<template>
  <div class="page">
    <Head />
    <Sidebar />
    <div class="body-main">
      <breadInfo />
      <div class="container bg flex-1 flex-v"></div>
    </div>
  </div>
</template>

<script>
import Head from "@/components/head.vue";
import Sidebar from "@/components/sidebar.vue";
import breadInfo from "@/components/breadInfo.vue";

export default {
  data() {
    return {
      addPop: true,
      itemList: [],
    };
  },
  components: {
    Head,
    Sidebar,
    breadInfo,
  },
  created() {
    var route = "";
    var projectId = Number(sessionStorage.getItem("projectId"));
    var projectList = JSON.parse(sessionStorage.getItem("projectList"));
    for (let i = 0; i < projectList.length; i++) {
      if (projectList[i].id === projectId) {
        route = projectList[i].domain;
      }
    }
    this.$router.push(route); //根据项目配置的路由，跳转到响应的项目首页
  },
  mounted() {},
  watch: {},
  methods: {},
};
</script>

<style scoped>
.pop .detail {
  padding-right: 20px;
}
.itemList {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 160px;
  justify-content: space-between;
}

.items {
  text-align: center;
  font-size: large;
  color: #fff;
  height: 40px;
}
</style>
